<template>
  <v-container id="coupons-list" fluid tag="section">
    <v-row justify="center">
      <v-col cols="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Add New
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              Add new Coupon item
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="couponObj.name"
                    class="purple-input"
                    label="Coupon Name"
                  />
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="couponObj.code"
                    class="purple-input"
                    label="Coupon Code"
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <v-select
                    v-model="couponObj.discount_type"
                    :items="codeTypeOptions"
                    item-text="label"
                    item-value="value"
                    label="Discount Type"
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="couponObj.discount_amount"
                    class="purple-input"
                    type="number"
                    label="Discount Value"
                    oninput="if(this.value < 0) this.value = 0; if(this.value > 80) this.value = 80;"

                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="couponObj.usage_limit"
                    class="purple-input"
                    type="number"
                    label="Total Usage Limit"
                    oninput="if(this.value < 0) this.value = 0;"
                  />
                </v-col>

                 <v-col cols="12">
                  <v-text-field
                    v-model="couponObj.single_usage_limit"
                    class="purple-input"
                    type="number"
                    label="Single person Usage Limit"
                    oninput="if(this.value < 0) this.value = 0;"
                  />
                </v-col>

                <v-col cols="12" md="12">
                  <v-dialog
                    ref="dialog"
                    v-model="dateModal"
                    :return-value.sync="couponObj.expiry_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="couponObj.expiry_date"
                        label="Expiry Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="couponObj.expiry_date" scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="dateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(couponObj.expiry_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12">
                  <v-checkbox v-model="couponObj.active" label="Active" />
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="
                      isEdit ? updateCouponAction() : createCouponAction()
                    "
                  >
                    {{ isEdit ? 'Update' : 'Create' }} Coupon
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-alert
                    v-if="alert.show"
                    border="left"
                    dense
                    text
                    :type="alert.type"
                  >
                    {{ alert.text }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col cols="6">
        <base-material-card color="info">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Available
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              (in List)
            </div>
          </template>
          <v-card class="mx-auto" outlined>
            <coupon-list
              :key="listKey"
              @deleteItem="deleteItem"
              @editItem="editItem"
            />
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createCoupon, updateCoupon, deleteCoupon } from '@/services/admin'
import couponList from '@/components/couponList'
export default {
  components: { couponList },
  data() {
    return {
      couponObj: {},
      date: new Date().toISOString().substr(0, 10),
      dateModal: false,
      codeTypeOptions: [
        { value: 'fixed', label: 'Fixed' },
        { value: 'percentage', label: 'Percent' },
      ],
      alert: {
        show: false,
        text: '',
        type: 'success',
      },
      isEdit: false,
      itemId: null,
      listKey: 'abc',
    }
  },
  computed: {},
  methods: {
    createCouponAction() {
      if (
        !this.couponObj.name ||
        !this.couponObj.code ||
        !this.couponObj.discount_type ||
        !this.couponObj.discount_amount ||
        !this.couponObj.usage_limit || !this.couponObj.single_usage_limit
      ) {
        this.alert.show = true
        this.alert.text = 'Please fill all required fields.'
        this.alert.type = 'error'
        return
      }

      if (!(Number(this.couponObj.usage_limit) > 0) || !(Number(this.couponObj.single_usage_limit) > 0)) {
        this.alert.show = true;
        this.alert.text = "Usage limits must be geater than zero";
        this.alert.type = "error";
        return;
      }
      if ((Number(this.couponObj.usage_limit) < Number(this.couponObj.single_usage_limit))) {
        this.alert.show = true;
        this.alert.text = "Single usage limit must be less than total usage limit";
        this.alert.type = "error";
        return;
      }

      createCoupon(this.couponObj).then((res) => {
        this.alert.show = true
        this.alert.text = 'Coupon Created.'
        this.alert.type = 'success'
        this.couponObj = {}
        this.listKey = new Date()
      })
    },
    updateCouponAction() {
      if (!this.couponObj.name ||
        !this.couponObj.code ||
        !this.couponObj.discount_type ||
        !this.couponObj.discount_amount ||
        !this.couponObj.usage_limit || !this.couponObj.single_usage_limit) {
        this.alert.show = true
        this.alert.text = 'Please fill all required fields.'
        this.alert.type = 'error'
        return
      }

      if (!(Number(this.couponObj.usage_limit) > 0) || !(Number(this.couponObj.single_usage_limit) > 0)) {
        this.alert.show = true;
        this.alert.text = "Usage limits must be geater than zero";
        this.alert.type = "error";
        return;
      }
      if ((Number(this.couponObj.usage_limit) < Number(this.couponObj.single_usage_limit))) {
        this.alert.show = true;
        this.alert.text = "Single usage limit must be less than total usage limit";
        this.alert.type = "error";
        return;
      }
      
      this.couponObj.slug = this.couponSlug
      updateCoupon(this.itemId, this.couponObj).then((res) => {
        // location.reload()
        this.alert.show = true
        this.alert.text = 'Coupon Updated.'
        this.alert.type = 'success'
        this.isEdit = false
        this.couponObj = {}
        this.listKey = new Date()
      })
    },
    editItem(items) {
      const data = JSON.parse(items)
      this.itemId = data._id
      delete data._id
      delete data.createdAt
      data.expiry_date = new Date(data.expiry_date).toISOString().split('T')[0]
      this.couponObj = data
      this.isEdit = true
      this.alert.show = false
    },
    async deleteItem(items) {
      const data = JSON.parse(items)
      this.itemId = data._id
      await deleteCoupon(this.itemId)
      location.reload()
    },
    getReadableDate(date) {
      const d = new Date(date)
      return d.toUTCString()
    },
  },
}
</script>
