<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12" class="py-5 px-8">
        <v-row>
          <v-text-field label="Search Coupons" append-icon="mdi-magnify" @change="search" />
        </v-row>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table :headers="headers" :items="coupons.data" :options.sync="tableOptions" :loading="loading"
        class="elevation-0" :footer-props="{
            'items-per-page-options': tableOptions.rowsPerPageItems,
          }">
        <template v-slot:[`item._id`]="{ item }">
          {{ item._id.substring(item._id.length - 5) }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ getReadableDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.isActive`]="{ item }">
          <v-chip v-if="item.isActive" class="ma-2" color="green" text-color="white">
            Yes
          </v-chip>
          <v-chip v-if="!item.isActive" class="ma-2" color="red" text-color="white">
            No
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <template v-for="(act, index) in coupons.actions">
            <v-tooltip :key="index" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="act === 'edit'
                    ? editItem(item)
                    : act === 'delete'
                      ? deleteItem(item)
                      : ''
                  ">
                  mdi-{{
                    act === 'edit' ? 'pencil' : act === 'delete' ? 'delete' : ''
                  }}
                </v-icon>
              </template>
              <span>{{ act }}</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { getCoupons } from '@/services/admin'
export default {
  data() {
    return {
      userFormOpen: false,
      usersStatus: 'all',
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: '_id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Code',
          align: 'start',
          sortable: false,
          value: 'code',
        },
        {
          text: 'Active',
          align: 'start',
          sortable: false,
          value: 'isActive',
        },
        {
          text: 'Created Date',
          align: 'start',
          sortable: false,
          value: 'createdAt',
        },
        {
          text: 'Action',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],
      coupons: {
        data: [],
        actions: ['edit', 'delete'],
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 200,
        totalItems: 0,
        rowsPerPageItems: [200, 500, 1000, 2000],
      },
      loading: true,
      searchKeyword: '',
    }
  },
  watch: {
    tableOptions: {
      handler() {
        this.loading = true
        const _self = this
        setTimeout(function () {
          _self.getData()
        }, 500)
      },
      deep: true,
    },
  },
  async mounted() {
    this.getData()
  },
  methods: {
    search(keyword) {
      this.searchKeyword = keyword
      this.getData()
    },
    userModalClose() {
      this.userFormOpen = false
      this.getData()
    },
    async getData() {
      console.log('getting this value here', this.searchKeyword);

      // const params = (this.searchKeyword == null) ? null : this.searchKeyword
      // this.searchKeyword = null

      const params = {
        status: this.usersStatus === 'all' ? null : this.usersStatus,
        search: this.searchKeyword,
        limit: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
        type: 'coupon',
      }
      getCoupons(params).then((res) => {
        this.coupons.data = res.data.coupon
        this.loading = false
      })

    },
    getReadableDate(date) {
      const d = new Date(date)
      return d.toUTCString()
    },
    editItem(item) {
      this.$emit('editItem', JSON.stringify(item))
      this.getData()

    },

    deleteItem(item) {
      if (!confirm('Are you sure you want to delete?')) return
      this.$emit('deleteItem', JSON.stringify(item))
      this.getData()

    },
  },
}
</script>
