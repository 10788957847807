var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"py-5 px-8",attrs:{"cols":"12"}},[_c('v-row',[_c('v-text-field',{attrs:{"label":"Search Coupons","append-icon":"mdi-magnify"},on:{"change":_vm.search}})],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.coupons.data,"options":_vm.tableOptions,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': _vm.tableOptions.rowsPerPageItems,
        }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:`item._id`,fn:function({ item }){return [_vm._v(" "+_vm._s(item._id.substring(item._id.length - 5))+" ")]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getReadableDate(item.createdAt))+" ")]}},{key:`item.isActive`,fn:function({ item }){return [(item.isActive)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" Yes ")]):_vm._e(),(!item.isActive)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" No ")]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_vm._l((_vm.coupons.actions),function(act,index){return [_c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){act === 'edit'
                  ? _vm.editItem(item)
                  : act === 'delete'
                    ? _vm.deleteItem(item)
                    : ''}}},'v-icon',attrs,false),on),[_vm._v(" mdi-"+_vm._s(act === 'edit' ? 'pencil' : act === 'delete' ? 'delete' : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(act))])])]})]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }